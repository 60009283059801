import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import '../styles/List.css';

interface Item {
  subscr_id: string;
  subscr_status: string;
  account_nif: string;
  account_legal_name: string;
  consultant_nif: string;
  consultant_legal_name: string;
  invoice_account_nif: string;
  invoice_account_legal_name: string;
  recurrency: string;
  invoicing_date: string | null;
  subscription_start_date: string | null;
  subscription_end_date: string | null;
  payment_method_code: string | null;
  item_description: string;
  pack_description: string | null;
  line_amount: number;
  remuneration_amount: number;
  invoicing_method: string;
  type: string;
  cancelled_at: string | null;
  cancellation_reason: string | null;
}

interface ListProps {
  items: Item[];
  orderPayment: 'ASC' | 'DESC';
  onOrderChange: () => void;
  totalItems: number;
  lastModified:string;
}


const List: React.FC<ListProps> = ({ items, orderPayment, onOrderChange, totalItems, lastModified }) => {
  const formattedDate = moment(lastModified).format('DD/MM/YYYY HH:mm:ss');


  return (
    <div className="table-container">
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            <strong>Total de Suscripciones:</strong> {totalItems}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Última actualización:        {lastModified
                      ? new Date(lastModified).toLocaleString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })
                      : ''}
          </Typography>
        </Box>
      </Paper>

      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered mt-4">
          <thead className="table-dark">
            <tr>
              <th scope="col">Cuenta Principal</th>
              <th scope="col">Nombre Legal Cuenta</th>
              <th scope="col">Cuenta Miembro</th>
              <th scope="col">Nombre Legal Cuenta Miembro</th>
              <th scope="col">Cuenta Facturación</th>
              <th scope="col">Nombre Legal Cuenta Facturación</th>
              <th scope="col">Estado</th>
              <th scope="col">
                <div className="header-container">
                Fecha Facturación
                  <Tooltip title={`Ordenar por ${orderPayment === 'ASC' ? 'descendente' : 'ascendente'}`}>
                    <IconButton onClick={onOrderChange} size="small" color="inherit">
                      {orderPayment === 'ASC' ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </th>
              <th scope="col">Fecha Inicio Suscripción</th>
              <th scope="col">Fecha Fin Suscripción</th>
              <th scope="col">Servicio</th>
              <th scope="col">Pack</th>
              <th scope="col">Importe</th>
              <th scope="col">Importe Remuneración</th>
              <th scope="col">Método de Facturación</th>
              <th scope="col">Tipo</th>
              <th scope="col">Recurrencia</th>
              <th scope="col">Método de Pago</th>
              <th scope="col">Fecha de Cancelación</th>
              <th scope="col">Razón de Cancelación</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 ? (
              items.map((item) => (
                <tr key={item.subscr_id}>
                  <td>{item.consultant_nif}</td>
                  <td>{item.consultant_legal_name}</td>
                  <td>{item.account_nif}</td>
                  <td>{item.account_legal_name}</td>
                  <td>{item.invoice_account_nif}</td>
                  <td>{item.invoice_account_legal_name}</td>
                  <td>{item.subscr_status}</td>
                  <td>
                    {item.invoicing_date
                      ? new Date(item.invoicing_date).toLocaleString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })
                      : ''}
                  </td>
                  <td>
                    {item.subscription_start_date
                      ? new Date(item.subscription_start_date).toLocaleString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })
                      : ''}
                  </td>
                  <td>
                    {item.subscription_end_date
                      ? new Date(item.subscription_end_date).toLocaleString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })
                      : ''}
                  </td>
                  <td>{item.item_description}</td>
                  <td>{item.pack_description}</td>
                  <td>{item.line_amount}</td>
                  <td>{item.remuneration_amount}</td>
                  <td>{item.invoicing_method}</td>
                  <td>{item.type}</td>
                  <td>{item.recurrency}</td>
                  <td>{item.payment_method_code}</td>
                  <td>
                    {item.cancelled_at
                      ? new Date(item.cancelled_at).toLocaleString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })
                      : ''}
                  </td>
                  <td>{item.cancellation_reason}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={18} className="text-center">
                  No se encontraron datos.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default List;
